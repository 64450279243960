import React from 'react';
import axios from 'axios';

const DeveloperRoadmap = () => {
  const [technicalCourses, setTechnicalCourses] = React.useState([]);

  React.useEffect(() => {
    fetchData();
  }, []);

  const fetchData = async () => {
    try {
      console.log('fetchData');
      const response = await axios.get('https://kavi-api-kavi-sekhon-276359988851.us-central1.run.app/api/data/');
      const data = response.data;
      setTechnicalCourses(data);
    } catch (error) {
      console.error('Error fetching data:', error);
    }
  };

  // Helper function to extract all unique categories from the fetched data
  const getUniqueCategories = () => {
    const categories = technicalCourses.map((course) => course.category);
    return [...new Set(categories)];
  };

  return (
    <section className="mb-8">
      <h2 className="text-3xl font-normal mb-28 text-center"></h2>
      <div className="flex justify-center">
        <div className="grid grid-cols-1 md:grid-cols-3 lg:grid-cols-5 gap-10 max-w-6xl mx-auto">
          {getUniqueCategories().map((category) => (
            <div key={category} className="col-span-1">
              <h3 className="text-xl font-semibold mb-4">{category}</h3>
              <ul className="list-disc pl-6">
                {technicalCourses.map((course, index) => {
                  if (course.category === category) {
                    return (
                      <li key={index}>
                        {course.link ? (
                          <a target="_blank" href={course.link} rel="noopener noreferrer" className="text-blue-500 hover:underline">
                            {course.article}
                          </a>
                        ) : (
                          <span className="text-grey-500">{course.article}</span>
                        )}
                      </li>
                    );
                  }
                  return null;
                })}
              </ul>
            </div>
          ))}
        </div>
      </div>
    </section>
  );
};

const Home = () => {
  console.log('Home2');

  return (
    <div className="container mx-auto mt-8">
      <section className="mb-8">
        {/* <p className="italic">
          Content pending. Please check back later.
        </p> */}
      </section>

      <DeveloperRoadmap />
    </div>
  );
};

export default Home;